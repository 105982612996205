<template>

<div >
    
    <div class="row justify-content-lg-center">
     <div class="cols-12 col-sm-12 col-lg-10 col-md-12 ">
        

          <!--begin::Dashboard-->
            <div class="row ">
              
              <div class="col-xl-8">
                <!-- <ListWidget1></ListWidget1> -->
              </div>
            <div class="col-xl-4">
            </div>
          </div>
          
          <div class="row py-5">

         
            
            <div class="col-xl-12 col-lg-12 ">
              <!-- QR CODE -->
              <div class="row py-5"  style="position: absolute; z-index: 9; left: 75% ">
                <div class="col-xl-12 col-lg-12 ">
                  <BrandQr></BrandQr>
                </div>
              </div>
              <!-- QR CODE -->
              <FormBrandDetail></FormBrandDetail>
            </div>
          </div>
         
          <div class="row py-5">
            <div class="col-xl-12 col-lg-12 ">
              <AdminCampaignListTable></AdminCampaignListTable>
            </div>
          </div>

     </div>
    </div>    
  </div>

</template>
<script>
import AdminCampaignListTable from "@/view/alex/eCouponAdminCampaignListTable.vue";
import FormBrandDetail from "@/view/alex/eCouponBrandForm.vue";
import BrandQr from "@/view/alex/eCouponBrandQr.vue";

// import ListWidget1 from "@/view/alex/eCouponMenuList.vue";

export default {
  name: "Branding1",
  data() {
    return {  
   
   }
  },

  created() {
    // this.tableDetail.data = this.items;

  },
  computed: {
    // ...mapGetters(["items"])
  },

  components: {
    AdminCampaignListTable,
    FormBrandDetail,
    BrandQr,
  },
  methods: {
    // setCheck(checked) {
    //   this.checked = checked;
    // }
  }
};
</script>
