<template>
  <div class="d-flex justify-end">
      <div class="cols-12 col-xl-12 col-lg-12 justify-end ">
        <span class="py-4" style="font-family: mitr; font-size: 0.8rem;" >เปิดกล้อง scan QR ที่นี่</span>
        <div style="cursor:pointer;" @click="toScan()">
          <!-- <i class="flaticon2-drop"></i> -->
          <inline-svg style="width:30px;height:30px;" src="media/svg/icons/Shopping/Barcode-read.svg" />
        <!-- <QRCanvas :options="theOptions()"></QRCanvas> -->
      </div>
      </div>

  </div>
</template>


<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-2.scss";
</style>

<script>


const Vue = require('vue');
const { QRCanvas } = require('qrcanvas-vue');


export default {
  name: "BrandForm",
  mixins: [],
  
  data() {
    return {
      finalUrl : "",
      options: {
          cellSize: 5,
          data: "",
        },
      url : "",
    }
  },
  async created() {


  
    

 let  brand = await this.$store.dispatch("brand/loadSingle");

// console.log(" 8888888 8 8 8 8 8 brand >>> ",brand );
  // let brandId = this.$route.params.brandId;
    // let verificationCode = this.$route.params.verificationCode

  // console.log(" 99 999 9 9 99  9 9 9QR brandId >>> ",brandId , "  verificationCode >>>  ",verificationCode );



  this.finalUrl = this.$store.getters["common/qrUrl"]  + "/mobile-scan-qr/" + brand.uid + "/" + brand.verificationCode;

  // this.options.data = this.$store.getters["common/qrUrl"]  + "/mobile-scan-qr/" + brandId + "/" + verificationCode;
    // console.log(" 99 999 9 9 99  9 9 9QR brand >>> ", this.options.data);
    // this.packageEnd = this.addDay(new Date(this.packageStart), 7);
  },

  mounted(){
    // let brandId = this.$route.params.brandId;
    // let verificationCode = this.$route.params.verificationCode
    
  },

  methods: {

    toScan(){
      window.location.href = this.finalUrl
      // console.log(this.finalUrl);
    },
    
    theOptions(){
      const options = {
                cellSize: 3,
                // correctLevel: 'H',
                data: this.finalUrl,
            }

      return options;
    },
  },
  computed: {
   
   },
   components:{
    //  QRCanvas,
   },
   watch:{
   
   },



};
</script>
